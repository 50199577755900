.p-tabview-selected {
  background-color: var(--primary-custom-color);
  border-top-right-radius: 8px !important;
  margin-bottom: 0.5em;
}

.p-tabview-ink-bar {
  background-color: var(--primary-custom-color) !important;
}

.p-tabview-nav-link {
  color: var(--primary-text-color) !important;
}

.p-tabview-nav-link:focus {
  color: #ffffff !important;
  border-top-right-radius: 8px !important;
}

.details-label {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  position: relative;
  top: 0.3em;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0115367em;
  /* Header Text */
  color: var(--primary-text-color);
  text-transform: capitalize;
}
.details-value {
  font-style: normal;
  font-weight: 600;
  position: relative;
  top: 0.3em;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0115367em;
  /* Text Blue */
  color: #5d7f8d;
}

.dispute-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.0115367em;
  /* Text Blue */
  color: var(--primary-text-color);
}
.dcir-row:after {
  content: "";
  display: table;
  clear: both;
}
.dcir-row {
  width: 99%;
  margin-left: 0.2em;
  border-bottom: 1px solid #e0e8f4 !important;
}

.dispute-lodged-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  letter-spacing: 1.21333px;
  text-transform: uppercase;
  /* Credit */
  color: #07b442;
}

.info-card-text {
  font-size: 13px;
}

.progress-bar-outer {
  background: rgb(238, 238, 238);
  height: 1.2rem;
  border-radius: 15px;
  margin: 0 1rem 2.5rem 0;
}

.progress-bar-inner {
  height: 100%;
  background: #0371ac;
  border-radius: 15px;
}

.progress-bar-inner p {
  color: #ffffff;
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 0;
}
