.breakdown-wrapper {
  border-radius: 10px;
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.recon.breakdown-card {
  padding: 0.5rem 1rem;
  background: #eceeff;
  width: 250px;
  border-radius: 5px;
}

.recon.breakdown-card:first-of-type {
  margin-right: 1rem;
}
