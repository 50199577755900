.p-error {
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 10px;
  position: relative;
  text-align: left;
  top: -0.2em;
  font-family: var(--primary-font-family);
}

.p-success {
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 10px;
  position: relative;
  color: var(--primary-custom-color);
  text-align: left;
  top: -0.2em;
  font-family: var(--primary-font-family);
}

.button.bare {
  background: none;
  border: none;
  outline: none;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}

.input-wrapper {
  position: relative;
}

label > span {
  position: absolute;
  top: 0;
  left: 10px;
  transform: translateY(20px);
  transition-duration: 300ms;
  font-size: 0.8rem;
  color: #727272;
}

.form-input label:focus-within > span,
.form-input input:not(:placeholder-shown) + span {
  color: #727272;
  transform: translateY(4px);
  font-size: 0.6rem;
}

.floating-label-input::placeholder {
  opacity: 0;
}

.floating-label-input {
  padding-top: 1.5rem;
  height: 55px;
  padding-left: 10px;
}

.validation-error {
  border: 1px solid var(--error-color) !important;
}
.validation-error:focus {
  border-color: var(--error-color) !important;
  outline-color: var(--error-color) !important;
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-loading-container {
  position: absolute;
  right: 0.75rem;
  top: 0.8rem;
}

.caret-hidden .p-multiselect .p-multiselect-trigger,
.caret-hidden .p-dropdown .p-dropdown-trigger {
  display: none;
}

.multiselect-loading-container .p-progress-spinner-svg {
  width: 1.25rem;
}

.label.radio-label span {
  margin-left: 0.1rem;
}
