.p-tabview-selected {
  background-color: var(--primary-custom-color);
  border-top-right-radius: 8px !important;
  margin-bottom: 0.5em;
}

.p-tabview-ink-bar {
  background-color: var(--primary-custom-color) !important;
}

.p-tabview-nav-link {
  color: var(--primary-text-color) !important;
}

.p-tabview-nav-link:focus {
  color: #ffffff !important;
  border-top-right-radius: 8px !important;
}

.details-label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  position: relative;
  top: 0.3em;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0115367em;
  /* Header Text */
  color: #5d7f8d;
}
.details-value {
  font-style: normal;
  font-weight: 600;
  position: relative;
  top: 0.3em;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0115367em;
  /* Text Blue */
  color: var(--primary-text-color);
}

.dispute-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.0115367em;
  /* Text Blue */
  color: var(--primary-text-color);
}
.custom-tab-icon-position {
  margin-top: 1.15em;
}

.custom-tab-icon {
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.713333px;
  /* Primary Blue */
  color: var(--primary-custom-color);
}

.dcir-row:after {
  content: "";
  display: table;
  clear: both;
}
.dcir-row {
  width: 99%;
  margin-left: 0.2em;
  border-bottom: 1px solid #e0e8f4 !important;
}

.download-button {
  background-color: #50b8ee;
  /* background-color: #f4803c; */
  color: white;
  border: none;
  padding: 0.6rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;
}

.loading-container.tab {
  position: initial !important;
  top: 0 !important;
}
