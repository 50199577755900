.progress-bar span {
  display: block;
}
.progress-bar .progress-outer {
  background: #dee2e8;
  height: 1.25rem;
  border-radius: 15px;
  /* margin: 0 1rem 2.5rem 0; */
  /* width: 100rem; */
  width: 60%;
}

.progress-bar .progress-inner {
  height: 100%;
  /* background: #0371ac; */
  /* background: #6266f1; */
  background: #50b8ee;
  /* background: #43be09 !important; */
  border-radius: 15px 0 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar .progress-inner.complete {
  border-radius: 15px;
}

.progress-bar .progress-inner span {
  color: #ffffff;
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
  /* padding-top: 0.2rem !important; */
}
