.image-modal {
  display: block;
  position: fixed;
  z-index: 1200;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
}

/* Modal Content/Box */
.modal-content {
  /* background-color: #fefefe; */
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  /* border: 1px solid #888; */
  width: 50%;
}

.close {
  color: #ffffff;
  float: left;
  font-size: 40px;
  font-weight: bold;
  padding-left: 1rem;
}

.close svg {
  color: #ffffff;
}

.close:hover,
.close:focus {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.image {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 930px) {
  .modal-content {
    width: 80%;
  }
}
