.custom-breadcrumb-text-active {
  color: var(--primary-custom-color);
  position: relative !important;
  text-align: left;
  /*margin-left:10px;*/
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

/* .metrics-card {
  border-radius: 15px;
  width: 50%;
  margin: 4rem 0;
  padding: 5rem 0;
  font-weight: bold;
  font-size: 1.25rem;
  color: #ffffff;
} */

.successful-transactions {
  background: #4ac1c0;
  margin-left: auto;
}

.failed-transactions {
  background: #ff6385;
  margin-right: auto;
}

.total-disputes {
  background: #ff6385;
  margin-left: auto;
  padding: 6rem 0;
}

.approved-disputes {
  background: #4ac1c0;
  margin-right: auto;
  padding: 6rem 0;
}

.card {
  background: #ffffff;
  box-shadow: 0px 1px 22px -12px #607d8b;
  border-radius: 10px;
  padding: 2rem !important;
}

.general-metrics {
  display: flex;
  text-align: left;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 1px 22px -12px #607d8b;
  margin-top: 0.5rem;
  padding: 1rem 0 1rem 0;
  color: #063a4f;
}

.general-metrics .metric-card {
  border-right: 1px solid #f1f3f5;
  width: 35%;
  padding: 0 2rem;
}

.metric-card p {
  font-size: 0.9rem;
  font-weight: 400;
  /* color: #6d8c98; */
}

.metric-card h1 {
  font-size: 1.5rem;
}

.general-metrics .metric-card:last-of-type {
  border-right: none;
}

.label input {
  height: 1rem !important;
  width: initial !important;
  transform: translateY(3px);
}

.label span {
  position: initial !important;
  color: #000000 !important;
  transform: translateY(0) !important;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.card.empty svg {
  width: 30rem;
}

.custom-table-refresh.dashboard {
  position: initial !important;
}

* {
  word-wrap: break-word;
}

@media screen and (max-width: 700px) {
  .dropdown-wrapper {
    width: 100% !important;
  }
  .date-select-row {
    width: 100% !important;
  }
  .metric-card {
    width: 100% !important;
    border-top: 1px solid #f1f3f5;
  }
  .general-metrics .metric-card:first-of-type {
    border-top: none;
  }
  .card.empty svg {
    width: 15rem;
  }
  .general-metrics {
    flex-wrap: wrap;
  }
}
