.tooltip-wrapper {
  position: relative;
}

.tooltip-wrapper .tip {
  position: absolute;
  /* background: rgb(126, 126, 126);
  color: #ffffff; */
  background: #0371ac;
  color: #ffffff !important;
  font-size: 0.8rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  min-width: 300px;
  max-width: 300px;
  z-index: 999;
  padding: 0.75rem 0.5rem;
  border-radius: 5px;
  text-align: left;
}
.tooltip-wrapper .tip p {
  color: #ffffff !important;
}

.tooltip-wrapper .tip.left {
  right: 0;
}
