.custom-upload-container {
  background: var(--body-color);
  /* background: rgb(252, 252, 252); */
  padding: 15px 20px;
  height: 70px;
  cursor: pointer;
  border: 0.5px dashed #6d8c98;
  border-radius: 8px;
}

.upload-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* Colors/LightBlue */
  color: var(--primary-custom-color);
}

.upload-grid-position {
  margin-left: -3.5em;
}

.upload-text {
  position: relative;
  top: -0.75em !important;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem !important;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: var(--primary-text-color);
  opacity: 0.5;
}

@media screen and (max-width: 765px) {
  .upload-grid-position {
    margin-left: -5.8em;
  }
}

@media screen and (max-width: 630px) {
  .upload-grid-position {
    margin-left: -5em;
  }
}

@media screen and (max-width: 575px) {
  .upload-grid-position {
    margin-left: -4.3em;
  }
}

@media screen and (max-width: 527px) {
  .upload-grid-position {
    margin-left: -3.8em;
  }
}

@media screen and (max-width: 500px) {
  .upload-grid-position {
    margin-left: -1.7em;
  }
}
