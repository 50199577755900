

.p-tabview-selected{
 background-color: var(--primary-custom-color);
  border-top-right-radius:8px!important;
  margin-bottom:.5em;
}

.p-tabview-ink-bar{
  background-color: var(--primary-custom-color)!important;
}

.p-tabview-nav-link{
    color:var(--primary-text-color)!important;
}

.p-tabview-nav-link:focus {
    color:#ffffff!important;
    border-top-right-radius:8px!important;
}

.details-label{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    position:relative;
    top:.3em;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.0115367em;
    /* Header Text */
    color: #5D7F8D;
}
.details-value{
    font-style: normal;
    font-weight: 600;
    position:relative;
    top:.3em;
    font-size: 13px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.0115367em;
    /* Text Blue */
    color: var(--primary-text-color);
}

.dispute-title{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.0115367em;
    /* Text Blue */
    color: var(--primary-text-color);
}
.dcir-row:after {
    content: "";
    display: table;
    clear: both;
}
.dcir-row{
    width: 99%;
    margin-left:.2em;
    border-bottom: 1px solid #E0E8F4!important;
}

