.merchant-toast .error-toast {
  max-width: 800px !important;
  padding-left: 16px !important;
}

.merchant-toast {
  padding-bottom: 0.5em;
}
.datatable-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.raiseTable {
  margin-top: -0.8em;
}

.custom-table-refresh {
  position: absolute;
  cursor: pointer;
  right: 0.1em;
}
.custom-table-refresh i {
  font-weight: 600;
  color: var(--primary-custom-color);
}
.custom-table-refresh span {
  color: var(--primary-custom-color);
}

.table .checkbox {
  height: 1.2rem;
  margin-top: 0.75rem;
}

/*.custom-table-refresh-icon i{*/
/*    fonts-size:1.5rem;*/
/*}*/

table {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.mobile-table-icon {
  position: relative;
  top: 0.5em;
}

.table-action-icon.view {
  margin-top: 0.5rem;
  display: block;
}

.mobile-table-label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  position: relative;
  top: 0.45em;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Header Text */
  color: #5d7f8d;
  mix-blend-mode: normal;
}

.mobile-table-value {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  position: relative;
  top: 0.4em;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Text Blue */
  color: var(--primary-text-color);
  mix-blend-mode: normal;
  word-wrap: break-word;
}

table th {
  font-style: normal;
  font-weight: normal;
  padding: 8px;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Header Text */
  color: #5d7f8d;
  mix-blend-mode: normal;
}

tr:nth-child(even) {
  background-color: var(--body-color);
}

table td {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  padding: 8px;
  line-height: 15px;
  /* Text Blue */
  color: var(--primary-text-color);
}

.mobile-table {
  position: relative;
  margin-top: 20px;
}

.empty-text {
  margin-top: 22px;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Header Text */
  color: #5d7f8d;
  text-align: center;
}

.empty-text-two {
  margin-top: -0.5em;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Header Text */
  color: #5d7f8d;
}

.empty-container {
  max-width: 300px;
  margin: 4em auto;
}
.adjust-empty-icon {
  margin-left: -1.8em;
}
