.dcir-row:after {
  content: "";
  display: table;
  clear: both;
}
.dcir-row {
  border-bottom: 1px solid #e0e8f4 !important;
  padding-left: 1em;
}

.dcir-tb-action-position {
  position: relative;
  top: 0.65em;
}

.dcir-column {
  float: left;
  text-align: left;
  width: 20%;
  padding: 10px;
}

.dcir-row > div:last-of-type .dcir-column {
  text-align: center;
  /* padding-right: 5rem; */
}

.dispute-response .dcir-row > div:last-of-type .dcir-column {
  text-align: left;
}

.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-card {
  background: #ffffff;
  /* min-width: 1152px; */
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 3px rgba(13, 79, 100, 0.12);
  border-radius: 0.5rem;
}

.table-action-icon {
  color: var(--primary-custom-color);
}

.table-action-icon-delete {
  color: var(--error-color);
}

.table-header p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Header Text */
  color: #5d7f8d;
  mix-blend-mode: normal;
}
.table-body-text p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  /* Text Blue */
  color: var(--primary-text-color);
}
.table-body-text .dcir-column {
  /* text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; */
  word-wrap: break-word;
}
.action-text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;
  /* Credit */
  color: var(--primary-custom-color);
}
