.loading-container {
  position: relative;
  top: 5em;
}
.p-calendar {
  display: block;
}
.p-inputtext {
  border: 1px solid #e1ecfd;
  height: 48px;
  border-radius: 4px;
}

.p-inputtext::placeholder {
  font-size: 14px;
}

.p-calendar .p-inputtext {
  width: 100%;
}

.p-datepicker {
  top: -372px !important;
}
