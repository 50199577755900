.title {
  font-size: 1rem;
  padding: 0.5rem 0 0.5rem 0;
}

.form-container {
  width: 40%;
}

.p-grid.users-filter {
  align-items: center;
}

.users-filter input {
  height: 48px;
  padding-left: 0.5rem;
}

.user-details-confirmation .dcir-row {
  margin-left: 0;
  padding-left: 0;
}

.users-filter button {
  height: 48px;
}

.primary-button.bulk-creation {
  background: #50b8ee;
}

.user-management .p-dialog {
  min-width: 550px !important;
}

.role-permissions {
  border: 1px solid #dadce0;
  border-radius: 5px;
}

.change-role p {
  color: #063a4f;
  font-size: 14px;
  font-weight: bold;
}

.role-permission {
  font-size: 13px !important;
  color: #063a4f;
  border-bottom: 1px solid #dadce0;
  margin: 0;
  padding: 0.75rem 1rem;
  font-weight: normal !important;
}

.user-management .success-message-text {
  font-weight: normal;
}

.permissions-loader {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

@media screen and (max-width: 800px) {
  .form-container {
    width: 100%;
  }
}
