.manual-payments .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.manual-payments .p-tabview .p-tabview-nav li .p-tabview-nav-link,
.manual-payments .p-tabview .p-tabview-nav,
.manual-payments .p-tabview .p-tabview-panels,
.manual-payments .p-tabview-selected {
  background: none !important;
}

.manual-payments .p-tabview-nav-link:focus {
  color: initial !important;
}

/* .manual-payments .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding-left: 0.5rem !important;
} */
