.recon-configs label {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-bottom: 0.25rem;
}

.recon-configs .p-dialog-content {
  min-width: 600px !important;
}

.recon-configs table td {
  text-align: left;
}

/* .recon-configs td:first-of-type {
  display: flex;
  align-items: center;
} */

.recon-configs .floating-label-input {
  padding: 0.5rem;
}

.recon-configs tr:nth-child(even) {
  background-color: #ffffff;
}

tr:first-of-type {
  font-weight: bold;
  padding: 1rem 0;
  background-color: var(--body-color);
}

tr:first-of-type td {
  padding: 1rem 0.5rem;
}

.recon-configs .config-rules tr:first-of-type {
  background-color: #ffffff;
}
