.details-label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  position: relative;
  top: 0.3em;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0115367em;
  /* Header Text */
  /*color: #5D7F8D;*/
  color: #5d7f8d;
}
.details-value {
  font-style: normal;
  font-weight: 600;
  position: relative;
  top: 0.3em;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0115367em;
  /* Text Blue */
  color: var(--primary-text-color);
}

.dispute-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.0115367em;
  /* Text Blue */
  color: var(--primary-text-color);
}

.dcir-row:after {
  content: "";
  display: table;
  clear: both;
}
.dcir-row {
  border-bottom: 1px solid #e0e8f4 !important;
}

.countdown-text {
  font-size: 0.9rem;
  margin: 0.5rem 0;
  /* font-weight: bold; */
  /* text-align: right; */
}

.bare-button {
  font-size: 0.9rem;
}

.loading {
  display: flex;
  justify-content: center;
}

.error-text {
  font-size: 0.85rem;
  margin-bottom: 0;
  color: red;
}

.retry-btn {
  background: none;
  outline: none;
  border: none;
  color: #0371ac;
  cursor: pointer;
  text-decoration: underline;
}

.confirmation-checkbox {
  height: initial !important;
  display: inline;
  width: initial;
}

.dispute-reason textarea {
  padding: 12px;
}

.back-office-disputes .p-tabview-selected,
.back-office-disputes .p-tabview .p-tabview-nav,
.back-office-disputes .p-tabview .p-tabview-panels,
.back-office-disputes
  .p-tabview
  .p-tabview-nav
  li.p-highlight
  .p-tabview-nav-link,
.back-office-disputes .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: transparent;
}

.back-office-disputes .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.75rem 1.25rem 0.75rem 1rem;
}

.back-office-disputes .p-tabview-nav-link:focus {
  color: #063a4f !important;
}

/* .back-office-disputes .p-tabview .p-tabview-panels {
  padding: 1rem 0;
} */
