.loading-container {
  position: relative;
  top: 5em;
}

.confirm-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  /* identical to box height */
  color: var(--primary-text-color);
}

.filter-button {
  background: none;
  border: none;
  outline: none;
  font-weight: bold;
  color: #0371ac;
  font-size: 1rem;
  cursor: pointer;
}
