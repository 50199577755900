.card {
  background: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  padding-bottom: 10rem !important;
}

.title {
  text-align: left;
  /* font-size: 1.2rem;
  font-weight: bold;
  color: #6d8c98;
  margin-top: 0; */
  margin-top: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #063a4f;
  padding-top: 0 !important;
}

.form-container {
  width: 50%;
  /* margin: 0 auto; */
}

.change-password-wrapper .primary-button {
  width: 100%;
  /* float: right; */
}
.change-password-wrapper input {
  margin: 0.25rem 0;
}

.change-password-wrapper .primary-button {
  max-width: initial;
}

@media screen and (max-width: 600px) {
  .form-container {
    width: 100%;
  }
}
